import { Button, Input, VStack } from "@chakra-ui/react";
import * as React from "react";
import { useForm } from "react-hook-form";
import { Form } from "components/input/form";
import { FormField } from "components/input/form-field";
import { useParams } from "hooks/use-params";
import { MetaTitle } from "components/meta-title";
import { LoginWizard } from "screens/login/hooks/use-login-wizard";

export function ResetPassword({
  currentStep: { onSubmit },
  isLoading,
}: LoginWizard) {
  const form = useForm<{ password: string; password_confirmation: string }>();
  const { token, email } = useParams();

  return (
    <>
      <MetaTitle>Reset Password</MetaTitle>
      <Form
        form={form}
        onSubmit={(data) => {
          return onSubmit({
            ...data,
            token: token,
            email: email,
          });
        }}
      >
        <VStack spacing={"8"}>
          <FormField
            name={"password"}
            label={"New Password"}
            Input={Input}
            inputProps={{
              type: "password",
              autoFocus: true,
              "data-form-type": "password",
              autoComplete: "new-password",
            }}
            required
          />
          <FormField
            name={"password_confirmation"}
            label={"Confirm New Password"}
            Input={Input}
            inputProps={{
              type: "password",
              autoComplete: "new-password",
              "data-form-type": "password,confirmation",
            }}
            required
          />
          <Button
            alignSelf={"flex-end"}
            variant={"solid"}
            type={"submit"}
            isLoading={isLoading}
            data-form-type={"action,change_password"}
          >
            Reset password
          </Button>
        </VStack>
      </Form>
    </>
  );
}
