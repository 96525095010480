import { defineStyleConfig } from "@chakra-ui/styled-system";

export const CloseButton = defineStyleConfig({
  baseStyle: {
    _hover: {
      bg: "brand.50",
      _disabled: {
        bg: "none",
      },
    },
  },
});
