import {
  Box,
  HStack,
  Input,
  SimpleGrid,
  Text,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { FormField } from "components/input/form-field";
import { EditableCard } from "patterns/editable-card";
import * as React from "react";
import { useProfile } from "screens/profile/hooks/use-profile";
import { useParams } from "hooks/use-params";

export function EditPasswordBlock() {
  const { changePassword } = useProfile();
  const { embedded } = useParams();
  const toast = useToast();

  const defaultValues = {
    password_current: "",
    password: "",
    password_confirmation: "",
  } as const;

  return (
    <EditableCard
      defaultValues={defaultValues}
      heading={"Password"}
      renderView={() => (
        <HStack justifyContent={"space-between"}>
          <VStack
            alignItems={"flex-start"}
            justifyContent={"flex-start"}
            flex={1}
            spacing={0}
          >
            <Text textStyle={"label"}>Current Password</Text>
            <Text>••••••••••</Text>
          </VStack>
        </HStack>
      )}
      renderEdit={() => (
        <SimpleGrid columns={2} spacing={4}>
          <FormField
            name={"password_current"}
            Input={Input}
            inputProps={{
              type: "password",
              autoCapitalize: "none",
              autoCorrect: "off",
              autoComplete: "current-password",
            }}
            label={"Current Password"}
            required
          />
          <Box />
          <FormField
            name={"password"}
            Input={Input}
            inputProps={{
              type: "password",
              autoCapitalize: "none",
              autoCorrect: "off",
            }}
            label={"New password"}
            required
          />
          <FormField
            name={"password_confirmation"}
            Input={Input}
            inputProps={{
              type: "password",
              autoCapitalize: "none",
              autoCorrect: "off",
            }}
            label={"Confirm new password"}
            required
          />
        </SimpleGrid>
      )}
      onSubmit={async (data) => {
        const result = await changePassword(data);
        if (!embedded && result)
          toast({
            title: "Password updated",
            position: "top",
            status: "success",
            containerStyle: {
              mt: "16",
            },
            size: "xl",
            duration: 5000,
          });
        return result;
      }}
    />
  );
}
