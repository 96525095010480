import Axios from "axios";

const axios = Axios.create({
  baseURL: import.meta.env.VITE_AUTH_SERVICE_API_URL,
  withCredentials: true,
  headers: { Accept: "application/json", "Content-Type": "application/json" },
});

axios.interceptors.response.use(
  (response) => response,
  async (error) => {
    const status = error?.response?.status;

    if (status === 419) {
      // Refresh CSRF
      await axios.get("csrf-cookie");

      // Return a new request using the original request's configuration
      return axios(error.response.config);
    }

    return Promise.reject(error);
  }
);

export { axios };
