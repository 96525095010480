import { forwardRef, HStack, PinInput, PinInputField } from "@chakra-ui/react";
import { ComponentProps, useEffect, useRef } from "react";

const OneTimePasswordInput = forwardRef(function OneTimePasswordInput(
  props: ComponentProps<typeof PinInput>
) {
  const pinRef = useRef<HTMLDivElement>(null);

  // We generate a one-off random ID to deliberately
  // break autocomplete in browsers like Chrome
  const randomId = useRef(Date.now().toString(36));

  useEffect(() => {
    if ((props.value?.length || 0) === 6) {
      pinRef.current?.closest("form")?.requestSubmit();
    }
  }, [props.value]);
  return (
    <div ref={pinRef}>
      <HStack justifyContent={"space-between"} w={"100%"}>
        <PinInput
          {...props}
          otp
          size={"lg"}
          placeholder={""}
          id={"otp-" + randomId.current}
        >
          <PinInputField
            data-form-type={"other"}
            autoFocus
            onFocus={(event) => {
              setTimeout(() => event.target.setSelectionRange(1, 1));
            }}
          />
          <PinInputField
            data-form-type={"other"}
            onFocus={(event) => {
              setTimeout(() => event.target.setSelectionRange(1, 1));
            }}
          />
          <PinInputField
            data-form-type={"other"}
            onFocus={(event) => {
              setTimeout(() => event.target.setSelectionRange(1, 1));
            }}
          />
          <PinInputField
            data-form-type={"other"}
            onFocus={(event) => {
              setTimeout(() => event.target.setSelectionRange(1, 1));
            }}
          />
          <PinInputField
            data-form-type={"other"}
            onFocus={(event) => {
              setTimeout(() => event.target.setSelectionRange(1, 1));
            }}
          />
          <PinInputField
            data-form-type={"other"}
            onFocus={(event) => {
              setTimeout(() => event.target.setSelectionRange(1, 1));
            }}
          />
        </PinInput>
      </HStack>
    </div>
  );
});

export { OneTimePasswordInput };
