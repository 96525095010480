import {
  StateMachineStepReturn,
  StateMachineSteps,
  useMultiStepWizard,
  Wizard,
} from "hooks/use-multi-step-wizard";
import { LoginError } from "errors/login-error";
import { APPS } from "config/apps";
import { EmailForm } from "screens/login/components/email-form";
import { PasswordForm } from "screens/login/components/password-form";
import { LoginMethodsForm } from "screens/login/components/login-methods-form";
import { MultiFactorVerify } from "screens/login/components/multi-factor-verify";
import { SelectAppForm } from "screens/login/components/select-app-form";
import { SelectAccountForm } from "screens/login/components/select-account-form";
import { AxiosError } from "axios";
import { Bugsnag } from "lib/bugsnag";
import { MultiFactorEnrolment, User } from "screens/profile/hooks/use-profile";
import { axios } from "lib/axios";
import {
  addParamsToUrl,
  getCurrentApp,
  getSearchParamsFromUrl,
} from "utils/routing";
import { useParams } from "hooks/use-params";
import { ForgotPassword } from "screens/forgot-password";
import { ResetPassword } from "screens/reset-password";
import { useEffect } from "react";
import { navigate } from "wouter/use-location";

declare global {
  interface Window {
    ReactNativeWebView?: {
      postMessage: (data: string) => void;
    };
  }
}

type AccessibleAccounts = {
  account_id: string;
  account_name: string;
  origin: string;
}[];

type State = {
  loginMethods?: { name: string; id: string; redirect?: string }[];
  email?: string;
  rememberMe?: boolean;
  requiresMultiFactor?: boolean;
  user?: User;
  selectedLoginMethod?: string;
  password?: string;
  isMultiFactorVerified?: boolean;
  multiFactorEnrolmentId?: string;
  accessibleAccounts: AccessibleAccounts;
  selectedAccount?: string;
  error?: string;
  isLoading?: boolean;
  account_id?: string;
  token?: string;
  code?: string;
};

export type LoginWizard = Wizard<State>;

function maybeRedirectToAuthService() {
  // If the redirect URL origin is the auth service itself,
  // the next steps (select app or select account) are unnecessary,
  // so lets just go directly to the redirect url
  const { redirect_url: redirectUrl } = getSearchParamsFromUrl(
    window.location.href
  );
  if (redirectUrl && new URL(redirectUrl).origin === window.location.origin) {
    navigate(redirectUrl);
    return true;
  }
}

export const apiActions = {
  fetchCsrf: async () => await axios.get("csrf-cookie"),
  fetchAvailableLoginMethods: async ({ data }) => {
    const app = getCurrentApp();
    let loginMethods = (
      await axios.get("available-login-methods", {
        params: { email: data?.email, app_id: app?.id },
      })
    ).data.result;

    if (
      (data as { email: string })?.email?.includes?.("appstore@rexsoftware")
    ) {
      loginMethods = loginMethods.filter(
        (method: { id: string }) => method.id === "password"
      );
    }

    return { loginMethods, ...data };
  },
  loginViaPassword: async ({ data, state }) => {
    const {
      data: { result: user },
    } = await axios.post<{ result: User }>("login", {
      ...data,
      email: state?.email,
      remember_me: true,
    });

    const multiFactorEnrolment = user.multi_factor_enrolments?.find?.(
      (mfe: MultiFactorEnrolment) => mfe.is_enabled
    );

    return {
      user,
      requiresMultiFactor: !!multiFactorEnrolment,
      multiFactorEnrolmentId: multiFactorEnrolment
        ? multiFactorEnrolment.id
        : undefined,
    };
  },
  fetchAccessibleAccounts: async ({ state }) => {
    // If the redirect url contains an account ID in its query param,
    // let's pull it out and rely on it (useful for rex permalinks)
    const { redirect_url: redirectUrl } = getSearchParamsFromUrl(
      window.location.href
    );

    const accountIdFromRedirectUrl = redirectUrl
      ? getSearchParamsFromUrl(redirectUrl, "?").account_id ||
        getSearchParamsFromUrl(redirectUrl, "#").account_id ||
        getSearchParamsFromUrl(redirectUrl, "#")._account_id
      : null;

    if (accountIdFromRedirectUrl) {
      await apiActions.selectAccount({
        data: { account_id: accountIdFromRedirectUrl },
        state,
      });
      return;
    }

    const app = getCurrentApp();

    const {
      data: { result: accessibleAccounts },
    } = await axios.get<{ result: AccessibleAccounts }>("accessible-accounts", {
      params: {
        app_id: app?.id === "rex_crm_mobile" ? "rex_crm" : app?.id,
      },
    });
    if (!accessibleAccounts || accessibleAccounts.length === 0) {
      throw new LoginError(
        `No accessible ${app?.name} accounts were found for this user.`
      );
    } else if (accessibleAccounts.length === 1) {
      const account = accessibleAccounts[0];
      await apiActions.selectAccount({
        data: account,
        state,
      });
    } else {
      return { accessibleAccounts };
    }
  },
  verifyMultiFactor: async ({ data, state }) => {
    const {
      data: { result: isMultiFactorVerified },
    } = await axios.post("multi-factor-verify", {
      ...data,
      enrolment_id: data?.enrolment_id || state?.multiFactorEnrolmentId,
    });

    if (!isMultiFactorVerified) throw new LoginError("Code is invalid");

    return { isMultiFactorVerified };
  },
  selectAccount: async ({ data, state }) => {
    const app = getCurrentApp();

    let paramsDelimiter = "?";
    const origin = (data as AccessibleAccounts[number]).origin;
    let paramsObject: Record<string, string> = {
      account_id: (data as AccessibleAccounts[number]).account_id,
      login_source: "authentication-service",
      ...(origin ? { origin } : {}),
      ...(state?.rememberMe ? { remember_me: "1" } : {}),
    };

    // For Rex CRM Mobile, the app cannot access the login cookie
    // so we need to pass along the login token rather than
    // have the app fetch it. Use hash param for better security.
    if (app?.id === "rex_crm_mobile") {
      // Rex uses non-JWT tokens which
      // is a deprecated approach. Newer
      // apps use JWT
      const {
        data: { result: login_token },
      } = await axios.get("login-token");
      paramsObject = { ...paramsObject, login_token };
      paramsDelimiter = "#";
    }

    if (app?.id === "rex_pm_inspections_app") {
      const {
        data: { result: login_token },
      } = await axios.get("login-jwt");
      paramsObject = { ...paramsObject, login_token };
      paramsDelimiter = "#";
    }

    // Delay the resolve of this function by an arbitrary amount
    // in order to accomodate for the external page load
    await new Promise<void>((resolve) => {
      const finalUrl = addParamsToUrl(
        getSearchParamsFromUrl(window.location.href).redirect_url ||
          app?.appUrl,
        paramsObject,
        paramsDelimiter
      );
      window.location.href = finalUrl;
      if (window?.ReactNativeWebView?.postMessage) {
        window.ReactNativeWebView.postMessage(
          JSON.stringify({
            eventType: "LOGIN",
            eventData: {
              url: finalUrl,
            },
          })
        );
      }
      setTimeout(() => {
        resolve();
      }, 10000);
    });
  },
  forgotPassword: async ({ data }) =>
    (await axios.post("forgot-password", data)).data.result,
  resetPassword: async ({ data }) => {
    await axios.post("reset-password", data);
    return { email: (data as { email: string }).email };
  },
  logout: async () => {
    await axios.post("logout");
  },
} as const satisfies Record<
  string,
  (args: {
    data?: Record<string, unknown>;
    state?: State;
  }) => StateMachineStepReturn<State>
>;

const mergeFormDataIntoState = ({
  data,
}: {
  data?: Partial<State>;
}): StateMachineStepReturn<State> => ({
  ...data,
});

const steps = {
  enterEmail: {
    Component: EmailForm,
    onSubmit: apiActions.fetchAvailableLoginMethods,
    getNextStep: (state) =>
      state?.loginMethods?.length === 1 &&
      state?.loginMethods?.find?.((m) => m.id === "password")
        ? "enterPassword"
        : "selectLoginMethod",
  },
  enterPassword: {
    Component: PasswordForm,
    onSubmit: apiActions.loginViaPassword,
    getNextStep: (state) => {
      const app = getCurrentApp();
      if (state.requiresMultiFactor) return "verifyMultiFactor";
      if (maybeRedirectToAuthService()) return null;
      if (!app) return "selectApp";
      return "selectAccount";
    },
  },
  selectLoginMethod: {
    Component: LoginMethodsForm,
    onSubmit: mergeFormDataIntoState,
    getNextStep: (state) => {
      if (state.requiresMultiFactor && !state.isMultiFactorVerified)
        return "verifyMultiFactor";
      if (state.selectedLoginMethod === "password") return "enterPassword";
      return "selectAccount";
    },
  },
  verifyMultiFactor: {
    Component: MultiFactorVerify,
    onSubmit: apiActions.verifyMultiFactor,
    getNextStep: () => {
      const app = getCurrentApp();
      if (maybeRedirectToAuthService()) return null;
      if (!app) return "selectApp";
      return "selectAccount";
    },
  },
  selectApp: {
    Component: SelectAppForm,
    onSubmit: mergeFormDataIntoState,
    getNextStep: (state) => {
      if (state.requiresMultiFactor && !state.isMultiFactorVerified)
        return "verifyMultiFactor";
      return "selectAccount";
    },
  },
  selectAccount: {
    Component: SelectAccountForm,
    onLoad: apiActions.fetchAccessibleAccounts,
    onSubmit: apiActions.selectAccount,
    getNextStep: () => null,
  },
  forgotPassword: {
    Component: ForgotPassword,
    title: "Forgot Password",
    onSubmit: apiActions.forgotPassword,
    getNextStep: () => null,
  },
  resetPassword: {
    Component: ResetPassword,
    title: "Reset Password",
    onSubmit: apiActions.resetPassword,
    getNextStep: () => "enterEmail",
  },
} as const satisfies StateMachineSteps<State>;

export type LoginSteps = typeof steps;

export function useLoginWizard({
  initialStepKey,
}: {
  initialStepKey?: keyof LoginSteps;
}) {
  const { error: errorParam } = useParams<{
    error: string;
    login_source?: string;
    remember_me?: string;
    app_id: (typeof APPS)[number]["id"];
  }>();

  useEffect(() => {
    apiActions.fetchCsrf();
  }, []);

  const wizard = useMultiStepWizard({
    initialState: {
      rememberMe: true,
      error: LoginError.CODES[errorParam],
      accessibleAccounts: [],
      email: window.localStorage.getItem("email") || "",
    } as State,
    initialStepKey,
    steps,
    onError: function handleError(
      e: Error,
      { setError, setCurrentStepKey, state }
    ): { state: State; error: string | undefined } {
      let newState = state,
        error;
      if (e instanceof LoginError) {
        error = e.message;
      } else if (
        e instanceof AxiosError &&
        e?.response?.data?.exception === "ValidationException"
      ) {
        error = e?.response?.data?.message;
      } else if (
        e instanceof AxiosError &&
        e?.response?.data?.exception === "MultiFactorException"
      ) {
        newState = {
          ...state,
          requiresMultiFactor: true,
          multiFactorEnrolmentId: e?.response?.data?.enrolment_id,
        };
        setCurrentStepKey("verifyMultiFactor");
      } else {
        Bugsnag.notify(e);
        console.error(e);
        error = "An unknown error occurred";
      }

      setError(error);

      return { state: newState, error };
    },
  });

  useEffect(() => {
    if (wizard.state.email) {
      Bugsnag.setUser(undefined, wizard.state.email);
    }
  }, [wizard.state.email]);

  return wizard;
}
