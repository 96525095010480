export const colors = {
  brand: {
    "50": "hsl(222,76%,97%)",
    "100": "hsl(222,85%,95%)",
    "200": "hsl(223,91%,91%)",
    "300": "hsl(222,81%,86%)",
    "400": "hsl(220,57%,32%)",
    "500": "hsl(220,74%,23%)",
    "600": "hsl(220,74%,23%)",
    "700": "hsl(220,74%,23%)",
    "800": "hsl(220,74%,23%)",
    "900": "hsl(220,74%,23%)",
  },
  gray: {
    "50": "hsl(0,0%,98%)",
    "100": "hsl(0,0%,96%)",
    "200": "hsl(0,0%,95%)",
    "300": "hsl(210,4%,91%)",
    "400": "hsl(220,4%,86%)",
    "500": "hsl(220,3%,80%)",
    "600": "hsl(214,3%,59%)",
    "700": "hsl(220,4%,46%)",
    "800": "hsl(210,5%,33%)",
    "900": "hsl(214,13%,21%)",
  },
  slate: {
    "50": "hsl(240,27%,98%)",
    "100": "hsl(252,29%,97%)",
    "200": "hsl(240,20%,92%)",
    "300": "hsl(240,15%,87%)",
    "400": "hsl(243,14%,73%)",
    "500": "hsl(243,11%,58%)",
    "600": "hsl(248,13%,42%)",
    "700": "hsl(249,15%,33%)",
    "800": "hsl(249,20%,25%)",
    "900": "hsl(249,35%,15%)",
  },
  sand: "#f8f7f1",
};
