import { defineStyleConfig } from "@chakra-ui/styled-system";

export const Button = defineStyleConfig({
  variants: {
    solid: (props) => {
      const { colorScheme: c } = props;

      const brightness = c === "gray" ? 200 : 500;

      return {
        bg: `${c}.${brightness}`,
        boxShadow: "rgba(47, 53, 61, 0.1) 0px 3px 9px",
        _hover: {
          bg: `${c}.${brightness - 100}`,
          _disabled: {
            bg: `${c}.${brightness}`,
          },
        },
        _active: { bg: `${c}.${brightness}` },
        _expanded: {
          bg: `${c}.${brightness - 100}`,
        },
      };
    },
    ghost: {
      color: "gray.900",
      _hover: {
        bg: "brand.50",
        _disabled: {
          bg: "none",
        },
      },
      _active: { bg: "brand.200" },
    },
  },
  sizes: {
    md: {
      h: "10",
    },
  },
  defaultProps: {
    colorScheme: "brand",
  },
});
