import { AnimatePresence, motion } from "framer-motion";
import * as React from "react";
import { ReactNode } from "react";

export function FadeIn({
  isVisible,
  childKey,
  children,
}: {
  isVisible: boolean;
  childKey: string;
  children: ReactNode;
}) {
  return (
    <AnimatePresence>
      {isVisible ? (
        <motion.div
          initial={{ opacity: 0, height: 0 }}
          animate={{ opacity: 1, height: "auto" }}
          exit={{
            opacity: 0,
            height: 0,
            transition: {
              height: { duration: 0.15, delay: 0.2 },
              opacity: { duration: 0.2 },
            },
          }}
          transition={{
            height: { duration: 0.15 },
            opacity: { delay: 0.15, duration: 0.15 },
          }}
          key={childKey}
        >
          {children}
        </motion.div>
      ) : null}
    </AnimatePresence>
  );
}
