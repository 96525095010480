import { Flex, Spinner } from "@chakra-ui/react";

export function PageSpinner() {
  return (
    <Flex w={"100%"} h={"60vh"} align={"center"} justify={"center"}>
      <Spinner
        thickness={"5px"}
        speed={"0.7s"}
        size={"xl"}
        emptyColor={"gray.300"}
        color={"blue.500"}
      />
    </Flex>
  );
}
