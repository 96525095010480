import { Button, HStack, Input, Spacer, Stack, VStack } from "@chakra-ui/react";
import { FormField } from "components/input/form-field";
import { useForm } from "react-hook-form";
import { Link } from "wouter";
import { CurrentEmail } from "screens/login/components/current-email";
import { Form } from "components/input/form";
import { LoginWizard } from "screens/login/hooks/use-login-wizard";

export function PasswordForm(wizard: LoginWizard) {
  const {
    state,
    isLoading,
    currentStep: { onSubmit },
  } = wizard;
  const form = useForm<Partial<typeof state>>({
    defaultValues: {
      password: state.password || "",
    },
  });

  return (
    <Form onSubmit={onSubmit} form={form}>
      <VStack spacing={"6"} align={"stretch"}>
        <CurrentEmail {...wizard} />
        <Stack spacing={"5"}>
          <FormField
            name={"password"}
            label={
              <>
                <span>Password</span>
                <Link to={"/forgot-password"}>
                  <Button
                    variant={"link"}
                    ml={"auto"}
                    colorScheme={"blue"}
                    size={"sm"}
                    px={"0"}
                    tabIndex={-1}
                  >
                    Forgot password?
                  </Button>
                </Link>
              </>
            }
            Input={Input}
            inputProps={{
              type: "password",
              autoFocus: true,
              autoCapitalize: "none",
              autoCorrect: "off",
              autoComplete: "current-password",
              "data-form-type": "password",
            }}
            required
          />
        </Stack>
        <Spacer />
        <HStack justify={"flex-end"} justifySelf={"flex-end"}>
          <Button
            alignSelf={"flex-end"}
            variant={"solid"}
            type={"submit"}
            flexShrink={"0"}
            isLoading={isLoading}
            data-form-type={"action,login"}
          >
            Log in
          </Button>
        </HStack>
      </VStack>
    </Form>
  );
}
