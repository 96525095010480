import { useEffect } from "react";

interface UseWarnOnUnsavedChangesOptions {
  hasUnsavedChanges: boolean;
  message?: string;
}

const defaultMessage =
  "You have unsaved changes. Are you sure you want to leave the page?";

function useWarnOnUnsavedChanges({
  hasUnsavedChanges,
  message = defaultMessage,
}: UseWarnOnUnsavedChangesOptions): void {
  const handleBeforeUnload = (e: BeforeUnloadEvent) => {
    if (hasUnsavedChanges) {
      e.preventDefault();
      e.returnValue = message;
      return message;
    }
  };

  useEffect(() => {
    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [hasUnsavedChanges, message]);
}

export { useWarnOnUnsavedChanges };
