import { createMultiStyleConfigHelpers } from "@chakra-ui/styled-system";
import { modalAnatomy } from "@chakra-ui/anatomy";
import { textStyles } from "theme/text-styles";

export const Modal = createMultiStyleConfigHelpers(
  modalAnatomy.keys
).defineMultiStyleConfig({
  baseStyle: {
    header: {
      px: 10,
      pt: 8,
      pb: 6,
      ...textStyles.h1,
    },
    body: {
      px: 10,
    },
    footer: {
      px: 10,
      py: 8,
    },
    closeButton: {
      top: 8,
      insetEnd: 10,
    },
  },
  defaultProps: {
    size: "3xl",
  },
});
