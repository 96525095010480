import { Button, HStack, Stack } from "@chakra-ui/react";
import { FormField } from "components/input/form-field";
import { useForm } from "react-hook-form";
import * as React from "react";
import { Select } from "lib/chakra-react-select";
import { APPS } from "config/apps";
import { Form } from "components/input/form";
import { Link } from "wouter";
import { LoginWizard } from "screens/login/hooks/use-login-wizard";

type FormData = {
  app_id: {
    value: typeof APPS[number]["id"];
    label: string;
  };
};

export function SelectAppForm({
  isLoading,
  currentStep: { onSubmit },
}: LoginWizard) {
  const form = useForm<FormData>();

  return (
    <Form
      form={form}
      onSubmit={(data) =>
        onSubmit({ app: APPS.find((a) => a.id === data.app_id.value) })
      }
    >
      <Stack spacing={"6"}>
        <Stack spacing={"5"}>
          <FormField
            name={"app_id"}
            label={"Select app"}
            Input={Select as any}
            inputProps={{
              options: APPS.filter((a) => a.id !== "rex_crm_mobile").map(
                (a) => ({ value: a.id, label: a.name })
              ),
            }}
            required
          />
        </Stack>
        <HStack justify={"space-between"} justifySelf={"flex-end"}>
          <Link to={"/profile"}>
            <Button
              as={"a"}
              alignSelf={"flex-end"}
              variant={"ghost"}
              target={"_blank"}
            >
              Edit Profile
            </Button>
          </Link>
          <Button
            alignSelf={"flex-end"}
            variant={"solid"}
            type={"submit"}
            isLoading={isLoading}
            data-form-type={"action,next"}
          >
            Next
          </Button>
        </HStack>
      </Stack>
    </Form>
  );
}
