import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import * as React from "react";
import { AxiosError } from "axios";
import { LoginError } from "errors/login-error";

declare global {
  interface Window {
    Bugsnag: typeof Bugsnag;
  }
}

declare const __BUILD_NUMBER__: string;

function BugsnagBoundary({ children }: { children: JSX.Element }) {
  if (!import.meta.env.VITE_BUGSNAG_API_KEY) return children;
  const Boundary = Bugsnag.getPlugin("react")?.createErrorBoundary(React);
  return Boundary ? <Boundary>{children}</Boundary> : children;
}

function initialiseBugsnag() {
  window.Bugsnag = Bugsnag;
  if (import.meta.env.VITE_BUGSNAG_API_KEY) {
    Bugsnag.start({
      apiKey: import.meta.env.VITE_BUGSNAG_API_KEY,
      plugins: [new BugsnagPluginReact()],
      redactedKeys: [/^password$/i],
      appVersion: __BUILD_NUMBER__,
      onError: (event) => {
        // Don't report Axios errors that are non-5xx
        if (
          (event.originalError instanceof AxiosError &&
            (event.originalError?.response?.status || 0) < 500) ||
          event.originalError instanceof LoginError
        ) {
          return false;
        }

        if (event.originalError instanceof AxiosError) {
          const { url, baseURL } = event.originalError.config || {};
          const { data, status } = event.originalError.response || {};

          event.addMetadata("Axios", {
            requestUrl: `${baseURL}/${url}`,
            statusCode: status,
            response: JSON.stringify(data),
          });
        }
      },
    });
  }
}

export { initialiseBugsnag, BugsnagBoundary, Bugsnag };
