import { Button, Heading, Input, VStack } from "@chakra-ui/react";
import * as React from "react";
import { useForm } from "react-hook-form";
import { Form } from "components/input/form";
import { FormField } from "components/input/form-field";
import { CheckCircleIcon } from "@chakra-ui/icons";
import { MetaTitle } from "components/meta-title";
import { LoginWizard } from "screens/login/hooks/use-login-wizard";

export function ForgotPassword({
  currentStep: { onSubmit },
  state,
  isLoading,
}: LoginWizard) {
  const form = useForm<Partial<typeof state>>({
    defaultValues: { email: state.email },
  });

  return (
    <>
      <MetaTitle>Forgot Password</MetaTitle>
      <Form form={form} onSubmit={onSubmit}>
        {form.formState.isSubmitSuccessful ? (
          <VStack spacing={"8"}>
            <CheckCircleIcon boxSize={"16"} color={"green.500"} />
            <Heading as={"h1"} size={"2xl"}>
              Reset link sent
            </Heading>
          </VStack>
        ) : (
          <VStack spacing={"8"}>
            <FormField
              name={"email"}
              label={"Email"}
              Input={Input}
              inputProps={{
                autoFocus: true,
                autoCapitalize: "none",
                autoComplete: "email",
                autoCorrect: "off",
                "data-form-type": "email",
              }}
              required
            />
            <Button
              alignSelf={"flex-end"}
              variant={"solid"}
              type={"submit"}
              isLoading={isLoading}
              data-form-type={"action,forgot_password"}
            >
              Send password reset email
            </Button>
          </VStack>
        )}
      </Form>
    </>
  );
}
