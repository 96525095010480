import { useRoute, useRouter } from "wouter";
import { usePathname, useSearch } from "wouter/use-location";
import { ROUTES_PRIVATE, ROUTES_PUBLIC } from "components/router";
import { useMemo } from "react";

export type Params = {
  login_source?: string;
  app_id?: string;
  error?: string;
  embedded?: string;
  enrolment_id?: string;
  redirect_url?: string;
  token?: string;
  email?: string
}

export function useParams<T = Params>() {
  const router = useRouter();
  const pathname = usePathname();
  const matcher = router.matcher;

  const matchedRoute = useMemo(
    () =>
      Object.keys({ ...ROUTES_PRIVATE, ...ROUTES_PUBLIC }).find((k) => {
        return matcher(k, pathname)[0];
      }) || "/",
    [pathname]
  );

  const [, params] = useRoute(matchedRoute);

  const searchParams = Object.fromEntries(new URLSearchParams(useSearch()));

  return { ...params, ...searchParams } as T;
}
