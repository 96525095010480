import { Button, HStack, VStack } from "@chakra-ui/react";
import * as React from "react";
import { useForm } from "react-hook-form";
import { Form } from "components/input/form";
import { FormField } from "components/input/form-field";
import { OneTimePasswordInput } from "components/input/one-time-password-input";
import { useParams } from "hooks/use-params";
import { LoginWizard } from "screens/login/hooks/use-login-wizard";

export function MultiFactorVerify({
  isLoading,
  currentStep: { onSubmit },
  state,
}: LoginWizard) {
  const form = useForm<{ code: string }>({
    defaultValues: {
      code: "",
    },
  });
  const code = form.watch("code");
  const { enrolment_id } = useParams();

  return (
    <>
      <Form
        form={form}
        onSubmit={async ({ code }) => {
          const result = await onSubmit({
            code,
            enrolment_id: enrolment_id || state.multiFactorEnrolmentId,
          });
          if (!result.isMultiFactorVerified) {
            form.setValue("code", "");
            (
              document.querySelector(
                "input[inputmode='numeric']"
              ) as HTMLInputElement
            )?.focus?.();
          }
        }}
      >
        <VStack spacing={"8"} align={"stretch"}>
          <HStack>
            <FormField
              name={"code"}
              label={"Enter your multi factor code"}
              Input={OneTimePasswordInput}
              required
            />
          </HStack>
          <Button
            alignSelf={"flex-end"}
            variant={"solid"}
            type={"submit"}
            isLoading={isLoading}
            isDisabled={code.length < 6}
            data-form-type={"action,next"}
          >
            Submit
          </Button>
        </VStack>
      </Form>
    </>
  );
}
