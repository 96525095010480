import "form-request-submit-polyfill";
import { ChakraProvider } from "@chakra-ui/react";
import * as React from "react";
import { StrictMode } from "react";
import * as ReactDOM from "react-dom/client";
import "@fontsource/inter/variable.css";
import { lunaTheme } from "theme/index";
import { Router } from "components/router";
import NiceModal from "@ebay/nice-modal-react";
import { ProfileProvider } from "screens/profile/hooks/use-profile";
import { HelmetProvider } from "react-helmet-async";
import { BugsnagBoundary, initialiseBugsnag } from "lib/bugsnag";
import { isOldVersionPocket } from "utils/user-agent";

/**
 * HACK: When we rolled out the Auth Service, we changed auth.rexsoftware.com
 * to point to the new frontend. The oversight was: Pocket clients were still pointing
 * to auth.rexsoftware.com url and expecting old auth!
 *
 * So, on the frontend here, we're detecting if its old pocket and them
 * manually taking them to auth-app.rexsoftware.com (old auth)
 */
if (isOldVersionPocket()) {
  window.location.href = "https://auth-app.rexsoftware.com/?app_id=pocket";
}

const isOldOidcRedirect = !!new URLSearchParams(window.location.search).get(
  "code"
);

if (isOldOidcRedirect) {
  window.location.href =
    "https://auth-app.rexsoftware.com/" + window.location.search;
}

initialiseBugsnag();

const rootElement = document.getElementById("root") as HTMLElement;

const app = (
  <BugsnagBoundary>
    <ChakraProvider theme={lunaTheme}>
      <HelmetProvider>
        <ProfileProvider>
          <NiceModal.Provider>
            {isOldOidcRedirect || isOldVersionPocket() ? null : <Router />}
          </NiceModal.Provider>
        </ProfileProvider>
      </HelmetProvider>
    </ChakraProvider>
  </BugsnagBoundary>
);

ReactDOM.createRoot(rootElement).render(<StrictMode>{app}</StrictMode>);
