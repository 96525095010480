import {
  Box,
  Button,
  Flex,
  Heading,
  Modal,
  ModalContent,
  Text,
} from "@chakra-ui/react";
import { MetaTitle } from "components/meta-title";
import * as React from "react";
import { create as createModal, useModal } from "@ebay/nice-modal-react";

const EmbeddedUpdated = createModal(function EmbeddedUpdated() {
  const modal = useModal();

  return (
    <Modal
      isOpen={modal.visible}
      onClose={modal.hide}
      onCloseComplete={modal.remove}
      size={"full"}
      isCentered
    >
      <ModalContent>
        <Flex
          bg={"white"}
          align={"center"}
          justify={"center"}
          textAlign={"center"}
          direction={"column"}
          pt={32}
        >
          <MetaTitle>Profile Updated</MetaTitle>
          <Box w={"100%"} maxW={"500px"}>
            <Heading size={"2xl"} mb={8}>
              Profile Updated
            </Heading>
            <Text mb={8} fontSize={"lg"}>
              Your profile has been updated. Your changes may take several
              minutes to show up in each Rex product.
            </Text>
            <Button onClick={() => window.close()}>
              Close Window & Reload
            </Button>
          </Box>
        </Flex>
      </ModalContent>
    </Modal>
  );
});

export { EmbeddedUpdated };
