import { defineStyleConfig } from "@chakra-ui/styled-system";
import { layerStyles } from "theme/layer-styles";

export const PinInput = defineStyleConfig({
  variants: {
    outline: {
      ...layerStyles["input-container"],
      transitionDuration: "0ms",
      _placeholder: {
        color: "slate.900",
      },
    },
  },
  baseStyle: {
    caretColor: "transparent",
    "&::selection": {
      backgroundColor: "transparent",
    },
  },
  sizes: {
    lg: {
      fontSize: "2xl",
      w: 14,
      h: 14,
      borderRadius: "lg",
    },
  },
});
