import { createMultiStyleConfigHelpers } from "@chakra-ui/styled-system";
import { cardAnatomy } from "@chakra-ui/anatomy";

export const Card = createMultiStyleConfigHelpers(
  cardAnatomy.keys
).defineMultiStyleConfig({
  sizes: {
    md: {
      header: {
        p: 7,
        color: "slate.900",
      },
      body: {
        p: 7,
        color: "slate.900",
      },
      footer: {
        p: 7,
      },
    },
  },
  variants: {
    elevated: {
      container: {
        shadow: "sm",
      },
    },
  },
});
