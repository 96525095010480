import {
  chakraComponents,
  createFilter,
  Select as ChakraReactSelect,
} from "chakra-react-select";
import { ComponentProps, forwardRef } from "react";
import { Box, Text } from "@chakra-ui/react";

const MAX_RENDERED_OPTIONS = 100;

const MenuList = ({
  children,
  ...props
}: ComponentProps<typeof chakraComponents["MenuList"]>) => {
  const hiddenCount = Array.isArray(children)
    ? children.slice(MAX_RENDERED_OPTIONS).length
    : 0;
  return (
    <chakraComponents.MenuList {...props}>
      {
        Array.isArray(children)
          ? children.slice(0, MAX_RENDERED_OPTIONS) /* Options */
          : children /* NoOptionsLabel */
      }
      {hiddenCount ? (
        <Box py={"1"} px={"13px"}>
          <Text color={"gray.500"}>+ {hiddenCount} more</Text>
        </Box>
      ) : null}
    </chakraComponents.MenuList>
  );
};

const components = { MenuList };

const Select = forwardRef<any>(function Select(
  props: ComponentProps<typeof ChakraReactSelect>,
  ref
) {
  return (
    <ChakraReactSelect
      filterOption={createFilter({ ignoreAccents: false })}
      {...props}
      components={components}
      ref={ref}
    />
  );
});

export { Select };
