import { colors } from "theme/colors";

export const styles = {
  global: {
    html: {
      "--chakra-ui-focus-ring-color": colors.brand["200"],
    },
    body: {
      color: colors.slate["900"],
      fontWeight: "medium",
      overflowY: "scroll",
      fontSize: "0.875rem",
      bg: colors.sand,
      height: "100vh",
      overflowX: "hidden",
    },
  },
};
