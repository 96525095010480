import { APPS } from "config/apps";

export function addParamsToUrl(
  url: string,
  params: Record<string, string>,
  delimiter = "?"
) {
  const urlObj = new URL(url);

  const searchParams = new URLSearchParams(urlObj.search);

  const hashParams = new URLSearchParams(urlObj.hash.split("#")[1]);

  for (const [key, value] of Object.entries(params)) {
    if (delimiter === "?") {
      searchParams.set(key, value);
    } else if (delimiter === "#") {
      hashParams.set(key, value);
    }
  }

  urlObj.search = "";
  urlObj.hash = "";

  const searchString = searchParams.toString()
    ? `?${searchParams.toString()}`
    : "";
  const hashString = hashParams.toString() ? `#${hashParams.toString()}` : "";

  return urlObj.toString() + searchString + hashString;
}

export function getSearchParamsFromUrl(
  url: string,
  delimiter: "?" | "#" = "?"
) {
  const urlObj = new URL(url);
  const searchParams = new URLSearchParams(
    delimiter === "?" ? urlObj.search : urlObj.hash.replace("#", "?")
  );
  return Object.fromEntries(searchParams);
}

export function getCurrentApp(): typeof APPS[number] {
  const { app_id } = getSearchParamsFromUrl(window.location.href);

  return (APPS.find((a) => a.id === app_id) ||
    APPS.find((a) => a.isDefault) ||
    APPS.find((a) => a.id === "rex_crm")) as typeof APPS[number];
}
