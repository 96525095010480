import { Button, HStack, Stack } from "@chakra-ui/react";
import { FormField } from "components/input/form-field";
import { useForm } from "react-hook-form";
import * as React from "react";
import { Select } from "lib/chakra-react-select";
import { orderBy } from "utils/array";
import { Form } from "components/input/form";
import { Link } from "wouter";
import { LoginWizard } from "screens/login/hooks/use-login-wizard";
import { CurrentEmail } from "screens/login/components/current-email";

export function SelectAccountForm(wizard: LoginWizard) {
  const form = useForm<{
    account: { value: string; label: string; origin?: string };
  }>();
  const {
    currentStep: { onSubmit },
    state: { accessibleAccounts },
    isLoading,
  } = wizard;

  return (
    <Form
      form={form}
      onSubmit={(d) =>
        onSubmit({ account_id: d.account.value, origin: d.account.origin })
      }
    >
      <Stack spacing={"6"}>
        <CurrentEmail {...wizard} />
        <Stack spacing={"5"}>
          <FormField
            name={"account"}
            label={"Select account"}
            Input={Select as any}
            inputProps={{
              captureMenuScroll: false,
              options: orderBy(
                accessibleAccounts?.map?.((a) => ({
                  value: a.account_id,
                  label: a.account_name,
                  origin: a.origin,
                })) || [],
                "label"
              ),
            }}
            required
          />
        </Stack>
        <HStack justify={"space-between"} justifySelf={"flex-end"}>
          <Link to={"/profile"}>
            <Button
              as={"a"}
              alignSelf={"flex-end"}
              variant={"outline"}
              target={"_blank"}
            >
              Edit Profile
            </Button>
          </Link>
          <Button
            alignSelf={"flex-end"}
            variant={"solid"}
            type={"submit"}
            isLoading={isLoading}
          >
            Log in
          </Button>
        </HStack>
      </Stack>
    </Form>
  );
}
