import { Box, HStack, Image, Text, VStack } from "@chakra-ui/react";
import * as React from "react";
import { ReactNode } from "react";
import { PublicBlobs } from "components/public-blobs";
import { useApp } from "hooks/use-app";

export function AppLayoutPublic({ children }: { children: ReactNode }) {
  const { app } = useApp();

  const shouldHideTermsForMobileApps =
    app?.id === "rex_crm_mobile" || app?.id === "rex_pm_inspections_app";

  const bullet = <Text userSelect={"none"}>•</Text>;
  return (
    <Box
      py={{ base: "12", md: "24" }}
      px={{ base: "0", sm: "8" }}
      h={{ base: "100vh", sm: "85vh" }}
      display={"flex"}
      alignItems={"center"}
      justifyContent={"center"}
      flexDir={"column"}
      bg={{ base: "white", sm: "transparent" }}
    >
      <PublicBlobs />
      <Image
        src={"/assets/rex-logo-blue-animated.svg"}
        w={"100%"}
        maxW={["120px", "160px"]}
        h={"100vh"}
        maxH={"64px"}
        mb={"8"}
      />
      <Box
        py={{ base: "0", sm: "10" }}
        px={{ base: "4", sm: "10" }}
        bg={"white"}
        shadow={{
          base: "none",
          sm: "xl",
        }}
        w={"100%"}
        maxW={"md"}
        mb={"8"}
        borderRadius={{ base: "none", sm: "xl" }}
      >
        {children}
      </Box>
      <VStack
        color={"slate.500"}
        fontSize={"xs"}
        fontWeight={"semibold"}
        pos={{ base: "absolute", sm: "relative" }}
        bottom={{ base: 4, sm: 0 }}
      >
        {!shouldHideTermsForMobileApps ? (
          <HStack spacing={"2"}>
            <Text
              as={"a"}
              _hover={{ opacity: 0.8 }}
              href={"https://www.rexsoftware.com/legal/rex-crm-api-terms"}
            >
              API Terms
            </Text>
            {bullet}
            <Text
              as={"a"}
              _hover={{ opacity: 0.8 }}
              href={"https://www.rexsoftware.com/legal/rex-crm-software-terms"}
            >
              Software Terms
            </Text>
            {bullet}

            <Text
              as={"a"}
              _hover={{ opacity: 0.8 }}
              href={"https://www.rexsoftware.com/legal/rex-crm-privacy-policy"}
            >
              Privacy
            </Text>
            {bullet}

            <Text
              as={"a"}
              _hover={{ opacity: 0.8 }}
              href={"https://www.rexsoftware.com/legal/cookie-statement"}
            >
              Cookies
            </Text>
          </HStack>
        ) : null}
        <Text>Copyright © {new Date().getFullYear()} Rex Software</Text>
      </VStack>
    </Box>
  );
}
