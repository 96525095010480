import { Button, Image, VStack } from "@chakra-ui/react";
import * as React from "react";
import { useState } from "react";
import { CurrentEmail } from "screens/login/components/current-email";
import { LoginWizard } from "screens/login/hooks/use-login-wizard";
import { useParams } from "hooks/use-params";
import { getCurrentApp } from "utils/routing";

function getIconForMethod(method: { id: string; name: string }) {
  if (method.id === "password") return undefined;
  return (
    <Image
      src={`/assets/oidc-methods/${method.name
        .toLowerCase()
        .replaceAll(" ", "-")}.png`}
      w={"19px"}
      mr={"1"}
    />
  );
}

export function LoginMethodsForm(wizard: LoginWizard) {
  const {
    state: { loginMethods },
    currentStep: { onSubmit },
  } = wizard;
  const app = getCurrentApp();
  const [chosenMethod, setChosenMethod] = useState<string>();
  const { redirect_url } = useParams();
  const [loginMethodsStale] = useState(loginMethods);

  return (
    <VStack spacing={"8"} align={"stretch"}>
      <CurrentEmail {...wizard} />
      <VStack align={"stretch"}>
        {loginMethodsStale?.map?.((method) => {
          const button = (
            <Button
              leftIcon={getIconForMethod(method)}
              variant={method.id === "password" ? "ghost" : "solid"}
              w={"100%"}
              isLoading={
                chosenMethod === method.id && app?.id !== "rex_crm_mobile"
              }
              onClick={() => {
                if (method.redirect) {
                  window.postMessage(JSON.stringify({ event: "oidc-clicked" }));
                }

                setChosenMethod(method.id);
                if (method.id === "password")
                  onSubmit({ selectedLoginMethod: "password" });
              }}
            >
              {method.name === "Password"
                ? "Use password instead"
                : `Log in via ${method.name}`}
            </Button>
          );

          const redirectParams = new URLSearchParams({
            ...(redirect_url ? { redirect_url } : {}),
            remember_me: "1",
          });

          return method.redirect ? (
            <a
              key={method.id}
              target={app?.id === "rex_crm_mobile" ? "_blank" : undefined}
              href={method.redirect + "?" + redirectParams.toString()}
              rel={"noreferrer"}
            >
              {button}
            </a>
          ) : (
            <div key={method.id}>{button}</div>
          );
        })}
      </VStack>
    </VStack>
  );
}
