import { FieldValues, FormProvider, UseFormReturn } from "react-hook-form";
import { BaseSyntheticEvent, FormHTMLAttributes, ReactNode } from "react";

type FormProps<Values extends FieldValues> = {
  onSubmit: (data: Values, event: React.BaseSyntheticEvent | undefined) => void;
  form: UseFormReturn<Values>;
  children: ReactNode;
} & Omit<FormHTMLAttributes<HTMLFormElement>, "onSubmit">;

export function Form<Values extends FieldValues>({
  form,
  onSubmit,
  children,
  ...rest
}: FormProps<Values>) {
  const onSubmitWrapper = (data: Values, event?: BaseSyntheticEvent) => {
    event?.preventDefault();
    return onSubmit(data, event);
  };
  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSubmitWrapper)} {...rest}>
        {children}
      </form>
    </FormProvider>
  );
}
