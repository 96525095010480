import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Heading,
  HStack,
  Icon,
  IconButton,
} from "@chakra-ui/react";
import { HiPencil } from "react-icons/hi";
import { ReactNode, useState } from "react";
import { DeepPartial, FieldValues, useForm } from "react-hook-form";
import { Form } from "components/input/form";
import { useWarnOnUnsavedChanges } from "hooks/use-has-unsaved-changes";
import { Bugsnag } from "lib/bugsnag";

type EditableCardProps<FormValues extends FieldValues> = {
  renderView: () => ReactNode;
  renderEdit: () => ReactNode;
  onSubmit: (data: FormValues) => Promise<unknown>;
  defaultValues?: DeepPartial<FormValues>;
  heading?: ReactNode;
};
export function EditableCard<FormValues extends FieldValues>({
  renderEdit,
  renderView,
  defaultValues,
  onSubmit,
  heading,
}: EditableCardProps<FormValues>) {
  const [isEditing, setIsEditing] = useState(false);

  const form = useForm<FormValues>({ defaultValues });
  const {
    formState: { isSubmitting },
  } = form;

  useWarnOnUnsavedChanges({ hasUnsavedChanges: isEditing });

  return (
    <Form
      form={form}
      onSubmit={async (data) => {
        try {
          const result = await onSubmit(data);
          if (result) setIsEditing(false);
        } catch (e) {
          Bugsnag.notify(e as Error);
          console.error(e);
        }
      }}
    >
      <Card
        shadow={isEditing ? "md" : "sm"}
        _hover={{ shadow: "md" }}
        role={"group"}
        onClick={() => {
          if (!isEditing) setIsEditing(true);
        }}
        transition={"box-shadow 0.2s ease-out"}
      >
        <CardHeader
          flexDir={"row"}
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          px={6}
          pt={4}
          pb={0}
        >
          {heading ? <Heading size={"md"}>{heading}</Heading> : null}
          {isEditing ? (
            <HStack spacing={4}>
              <Button variant={"ghost"} onClick={() => setIsEditing(false)}>
                Cancel
              </Button>
              <Button type={"submit"} isLoading={isSubmitting}>
                Save Changes
              </Button>
            </HStack>
          ) : (
            <IconButton
              aria-label={"Edit"}
              variant={"ghost"}
              onClick={() => setIsEditing(true)}
            >
              <Icon as={HiPencil} boxSize={"5"} />
            </IconButton>
          )}
        </CardHeader>
        <CardBody p={6}>{isEditing ? renderEdit() : renderView()}</CardBody>
      </Card>
    </Form>
  );
}
