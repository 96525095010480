import { chakra, Spacer, Tag, TagCloseButton } from "@chakra-ui/react";
import { useState } from "react";
import { LoginWizard } from "screens/login/hooks/use-login-wizard";

export function CurrentEmail({ state: { email }, resetState }: LoginWizard) {
  const [emailStale] = useState(email);

  return (
    <Tag borderRadius={"full"} size={"lg"} py={2} px={4}>
      <chakra.input
        type={"email"}
        name={"email"}
        value={emailStale}
        aria-hidden={"true"}
        display={"none"}
        tabIndex={-1}
      />
      {emailStale}
      <Spacer />
      <TagCloseButton onClick={() => resetState()} />
    </Tag>
  );
}
