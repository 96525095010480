import { useEffect } from "react";
import { apiActions } from "screens/login/hooks/use-login-wizard";

export function useEffectLogout(isLogout: boolean) {
  useEffect(() => {
    if (isLogout) {
      apiActions.logout();
    }
  }, [isLogout]);
}
