import { APPS } from "config/apps";
import useLocation from "wouter/use-location";
import { useEffect } from "react";
import { addParamsToUrl } from "utils/routing";
import { useSearchParams } from "hooks/use-search-params";

export function useEffectAppIdLocalStorage(
  appId: string | typeof APPS[number]["id"] | undefined
) {
  const [, setLocation] = useLocation();
  const searchParams = useSearchParams();
  const isLogout = Object.keys(searchParams).includes("logout");

  const lastUsedAppId = window.localStorage.getItem("last_app_id");
  const appIdIsValid = (id: string | null) => APPS.find((a) => a.id === id);
  const defaultAppId = APPS.find((a) => a.isDefault)?.id || "rex_crm";

  useEffect(() => {
    if (!appId) {
      setLocation(
        addParamsToUrl(window.location.href, {
          app_id: appIdIsValid(lastUsedAppId)
            ? (lastUsedAppId as string)
            : defaultAppId,
        }),
        { replace: true }
      );
    } else {
      window.localStorage.setItem(
        "last_app_id",
        appIdIsValid(appId) ? appId : defaultAppId
      );
    }

    if (isLogout) {
      window.localStorage.removeItem("last_app_id");
    }
  }, [appId, isLogout]);
}
