import { Button, Flex, Input, Text, VStack } from "@chakra-ui/react";
import { FormField } from "components/input/form-field";
import { useMultiStepWizard } from "hooks/use-multi-step-wizard";

export function VerifyPassword({
  isLoading,
}: ReturnType<typeof useMultiStepWizard>) {
  return (
    <VStack spacing={6} align={"stretch"}>
      <Text>
        Almost done! For security reasons, you must confirm your password before
        enabling a new multi factor method
      </Text>
      <FormField
        name={"password_confirmation"}
        label={"Confirm password"}
        Input={Input}
        inputProps={{
          type: "password",
          autoFocus: true,
          autoCapitalize: "none",
          autoCorrect: "off",
        }}
        required
      />
      <Flex justify={"flex-end"} py={"8"}>
        <Button type={"submit"} isLoading={isLoading}>
          Activate multi factor method
        </Button>
      </Flex>
    </VStack>
  );
}
