import { useEffect } from "react";

export function useEffectEmailLocalStorage(email: string | undefined) {
  useEffect(() => {
    if (!email) {
      window.localStorage.removeItem("email");
    } else {
      window.localStorage.setItem("email", email);
    }
  }, [email]);
}
