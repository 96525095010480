import { Box, Container, Flex, useToast } from "@chakra-ui/react";
import { NavMenu } from "patterns/nav-menu";
import { ReactNode, useEffect } from "react";
import { useProfile } from "screens/profile/hooks/use-profile";

export function AppLayoutPrivate({ children }: { children: ReactNode }) {
  const { error, getUser } = useProfile();

  useEffect(() => {
    getUser();
  }, []);

  const toast = useToast();

  useEffect(() => {
    if (error) {
      toast({
        description: error,
        position: "top",
        status: "error",
        containerStyle: {
          mt: "16",
        },
        size: "xl",
        duration: 5000,
      });
    }
  }, [error]);
  return (
    <Flex>
      <NavMenu />
      <Box flexShrink={1} w={"100%"} ml={{ nav: "0", base: "184px" }}>
        <Container
          maxW={"2xl"}
          p={8}
          flexGrow={1}
          ml={{ base: "0", nav: "auto" }}
          alignItems={{ base: "flex-start", nav: "center" }}
        >
          {children}
        </Container>
      </Box>
    </Flex>
  );
}
