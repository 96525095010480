import { Alert, AlertDescription, AlertIcon, Box, Link as LinkText } from "@chakra-ui/react";
import { FadeIn } from "components/fade-in-presence";
import * as React from "react";
import { useParams } from "hooks/use-params";
import { Link } from "wouter";

export function FormError({ error }: { error: string | undefined }) {
  const { app_id } = useParams();
  return (
    <FadeIn isVisible={!!error} childKey={"error"}>
      <Box pb={"4"}>
        <Alert status={"error"} key={error}>
          <AlertIcon />
          <AlertDescription>{error}</AlertDescription>
        </Alert>
      </Box>
      {app_id === "rex_pm" && error === "These credentials do not match our records." ? <Box pb={"4"}>
        <Alert status={"warning"} key={error}>
          <AlertIcon alignSelf={"flex-start"} />
          <AlertDescription lineHeight={1.6}>Rex PM and Rex CRM login details are now shared.<br/><br/>If you had different passwords for Rex PM and Rex CRM, you should login using your Rex CRM password. Alternatively, you can <LinkText as={Link} to={"/forgot-password"} color={"blue.500"}>reset your password here.</LinkText></AlertDescription>
        </Alert>
      </Box>: null}
    </FadeIn>
  );
}
