import { HStack, Input, SimpleGrid, Text, useToast, VStack, } from "@chakra-ui/react";
import { useProfile } from "screens/profile/hooks/use-profile";
import { EditableCard } from "patterns/editable-card";
import { FormField } from "components/input/form-field";
import { HelpBanner } from "components/help-banner";
import helpBannerPersonalImage from "assets/help-banner-personal.png";
import * as React from "react";
import { PageSpinner } from "components/page-spinner";
import { useParams } from "hooks/use-params";
import { PageHeader } from "screens/profile/components/page-header";
import { ReactComponent as personIcon } from "assets/icons/person.svg";

export function PersonalInfo() {
  const { user, updateUser } = useProfile();
  const { embedded } = useParams();
  const toast = useToast();

  if (!user) return <PageSpinner />;

  const defaultValues = {
    first_name: user.first_name,
    last_name: user.last_name,
    email: user.email,
  } as const;

  return (
    <>
      <PageHeader
        heading={"Personal Info"}
        Icon={personIcon}
        description={"Your basic details and preferences"}
      />
      <VStack spacing={6} align={"stretch"}>
        <HelpBanner
          heading={"One profile, all of Rex"}
          description={
            "Any changes here will be eventually be shared across all Rex products and services."
          }
          image={helpBannerPersonalImage}
        />
        <EditableCard
          heading={"Basic Details"}
          defaultValues={defaultValues}
          renderView={() => (
            <HStack justifyContent={"space-between"}>
              <VStack
                alignItems={"flex-start"}
                justifyContent={"flex-start"}
                flex={1}
                spacing={0}
              >
                <Text textStyle={"label"}>Name</Text>
                <Text>
                  {user.first_name} {user.last_name}
                </Text>
              </VStack>
              <VStack
                alignItems={"flex-start"}
                justifyContent={"flex-start"}
                flex={1}
                spacing={0}
              >
                <Text textStyle={"label"}>Email</Text>
                <Text>{user.email}</Text>
              </VStack>
            </HStack>
          )}
          renderEdit={() => (
            <SimpleGrid columns={2} spacing={4}>
              <FormField
                name={"first_name"}
                Input={Input}
                label={"First name"}
              />
              <FormField name={"last_name"} Input={Input} label={"Last name"} />
              <FormField name={"email"} Input={Input} label={"Email"} />
            </SimpleGrid>
          )}
          onSubmit={async (data) => {
            const result = await updateUser(data);
            if (!embedded && result) {
              toast({
                title: "Profile updated",
                description:
                  "Changes may take several minutes to be reflected in other applications",
                position: "top",
                status: "success",
                containerStyle: {
                  mt: "16",
                },
                size: "xl",
                duration: 4000,
              });
            }
            return result;
          }}
        />
      </VStack>
    </>
  );
}
