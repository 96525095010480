const APPS = [
  {
    name: "Rex CRM",
    id: "rex_crm",
    isDefault: true,
    logoutUrl: import.meta.env.VITE_APP_REX_URL + "/actions/remote_logout",
    appUrl: import.meta.env.VITE_APP_REX_URL,
  },
  {
    name: "Rex CRM Mobile",
    id: "rex_crm_mobile",
    isDefault: false,
    logoutUrl: import.meta.env.VITE_APP_REX_URL + "/actions/remote_logout",
    appUrl: import.meta.env.VITE_APP_REX_CRM_MOBILE_URL,
  },
  {
    name: "Rex PM Inspections",
    id: "rex_pm_inspections_app",
    isDefault: false,
    logoutUrl: "",
    appUrl: import.meta.env.VITE_APP_REX_PM_INSPECTIONS_APP_URL,
  },
  {
    name: "Rex Group App",
    id: "rex_crm_group_app",
    isDefault: false,
    logoutUrl: "",
    appUrl: import.meta.env.VITE_APP_REX_GROUP_APP_URL,
  },
  {
    name: "Reach Account Management",
    id: "rex_reach_account_management",
    isDefault: false,
    logoutUrl: "",
    appUrl: import.meta.env.VITE_APP_REX_REACH_ACCOUNT_MANAGEMENT_URL,
  },
  {
    name: "Single View",
    id: "single_view",
    isDefault: false,
    logoutUrl: "",
    appUrl: import.meta.env.VITE_APP_SINGLE_VIEW_URL,
  },
  {
    name: "Rex PM",
    id: "rex_pm",
    isDefault: false,
    logoutUrl: "",
    appUrl: import.meta.env.VITE_APP_REX_PM_URL,
  },
] as const;

export { APPS };
