import { AnimatePresence, motion } from "framer-motion";
import * as React from "react";
import { ReactNode, useEffect, useState } from "react";
import useMeasure from "react-use-measure";
import { usePreviousDistinct } from "hooks/use-previous-distinct";

export function CrossFadeSwitch({
  children,
  childKey,
  duration = 0.15,
}: {
  children: ReactNode;
  childKey: string | number;
  duration?: number;
}) {
  const [ref, { height }] = useMeasure();
  const [heightAnimationActive, setHeightAnimationActive] = useState(false);
  const previousChildKey = usePreviousDistinct(childKey);

  // Avoid unsightly initial jitters by only animating height
  // if the childKey meaningfully changes
  useEffect(() => {
    if (previousChildKey && childKey !== previousChildKey) {
      setHeightAnimationActive(true);
    }
  }, [childKey, previousChildKey]);

  return (
    <motion.div
      animate={{ height: heightAnimationActive ? height || "auto" : "auto" }}
      initial={{ height: heightAnimationActive ? height || "auto" : "auto" }}
      transition={{
        duration,
        type: "tween",
        ease: "easeOut",
      }}
    >
      <AnimatePresence mode={"wait"} initial={false}>
        <motion.div
          key={childKey}
          initial={{ opacity: 0 }}
          animate={{
            opacity: 1,
            transition: { delay: duration / 2, duration },
          }}
          exit={{ opacity: 0 }}
          transition={{ duration }}
          onAnimationComplete={(def) => {
            // @ts-ignore
            if (def.opacity === 1) {
              setHeightAnimationActive(false);
            }
          }}
        >
          <div ref={ref}>{children}</div>
        </motion.div>
      </AnimatePresence>
    </motion.div>
  );
}
