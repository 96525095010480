import useLocation, { usePathname } from "wouter/use-location";
import { Dispatch, SetStateAction, useEffect } from "react";
import { LoginError } from "errors/login-error";

export function useEffectError(error: string | undefined, setError: Dispatch<SetStateAction<string | undefined>>) {
  const [, setLocation] = useLocation();
  const pathname = usePathname();

  useEffect(() => {
    if (error?.startsWith("ERR_")) {
      setError(LoginError.CODES[error]);
      setLocation(pathname);
    }
  }, [error]);
}
