export const fontWeights = {
  hairline: 150,
  thin: 200,
  light: 300,
  normal: 400,
  medium: 500,
  semibold: 600,
  bold: 700,
  extrabold: 775,
  black: 850,
};
