import {
  Card,
  CardBody,
  CardHeader,
  Heading,
  Spacer,
  Text,
} from "@chakra-ui/react";
import { ReactNode } from "react";

export function HelpBanner({
  heading,
  description,
  image,
}: {
  heading: string;
  description: ReactNode;
  image: string;
}) {
  return (
    <Card
      bg={"white"}
      borderRadius={"lg"}
      justify={"space-between"}
      bgImage={image}
      bgPosition={"101% center"}
      bgSize={"contain"}
      bgRepeat={"no-repeat"}
    >
      <CardHeader pb={"0"}>
        <Heading size={"md"} mb={1}>
          {heading}
        </Heading>
      </CardHeader>
      <CardBody w={"60%"} pt={"2"}>
        <Text>{description}</Text>
      </CardBody>
      <Spacer />
    </Card>
  );
}
