import {
  BoxProps,
  Divider,
  Stack,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import { StepCircle } from "screens/profile/components/steps/step-circle";

interface StepProps extends BoxProps {
  title?: string;
  description?: string;
  isCompleted: boolean;
  isActive: boolean;
  isLastStep: boolean;
  isFirstStep: boolean;
}

export function Step(props: StepProps) {
  const {
    isActive,
    isCompleted,
    isLastStep,
    isFirstStep,
    title,
    description,
    ...stackProps
  } = props;
  const isMobile = useBreakpointValue({ base: true, md: false });

  const orientation = useBreakpointValue<"horizontal" | "vertical">({
    base: "vertical",
    md: "horizontal",
  });

  return (
    <Stack
      spacing={"2"}
      direction={{ base: "row", md: "column" }}
      flex={"1"}
      {...stackProps}
    >
      <Stack
        spacing={"0"}
        align={"center"}
        direction={{ base: "column", md: "row" }}
      >
        <Divider
          display={isMobile ? "none" : "initial"}
          orientation={orientation}
          borderWidth={"1px"}
          opacity={1}
          borderColor={
            isFirstStep
              ? "transparent"
              : isCompleted || isActive
              ? "brand.500"
              : "gray.400"
          }
        />
        <StepCircle isActive={isActive} isCompleted={isCompleted} />
        <Divider
          orientation={orientation}
          borderWidth={"1px"}
          opacity={1}
          borderColor={
            isCompleted ? "brand.500" : isLastStep ? "transparent" : "gray.400"
          }
        />
      </Stack>
      <Stack
        spacing={"0.5"}
        pb={isMobile && !isLastStep ? "8" : "0"}
        align={{ base: "start", md: "center" }}
      >
        {title ? (
          <Text color={"emphasized"} fontSize={"xs"}>
            {title}
          </Text>
        ) : null}
        <Text
          color={isActive || isCompleted ? "brand.900" : "gray.500"}
          fontSize={"sm"}
          fontWeight={"semibold"}
        >
          {description}
        </Text>
      </Stack>
    </Stack>
  );
}
