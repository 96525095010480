import * as React from "react";
import { ComponentType } from "react";
import { MetaTitle } from "components/meta-title";
import { Box, Heading, Icon, Text, VStack } from "@chakra-ui/react";

export function PageHeader({
  heading,
  description,
  Icon,
}: {
  heading: string;
  Icon: ComponentType<any>;
  description: string;
}) {
  const iconProps = {
    width: "24px",
    height: "24px",
  };

  return (
    <VStack
      as={"header"}
      spacing={4}
      display={"flex"}
      align={"center"}
      justify={"flex-start"}
      textAlign={"center"}
      direction={"column"}
      mb={8}
    >
      <MetaTitle>{heading}</MetaTitle>
      <Box
        borderWidth={"3px"}
        borderColor={"slate.900"}
        p={"2"}
        borderRadius={"100%"}
      >
        <Icon as={Icon} {...iconProps} />
      </Box>
      <Heading size={"3xl"} mt={0}>
        {heading}
      </Heading>
      <Text fontSize={"lg"}>{description}</Text>
    </VStack>
  );
}
