import { Stack } from "@chakra-ui/react";
import { Step } from "screens/profile/components/steps/step";
import { StateMachineSteps } from "hooks/use-multi-step-wizard";

export function Steps<StepsType extends StateMachineSteps<any>>({
  steps,
  currentStep,
  stepHistory,
}: {
  steps: StepsType;
  stepHistory: (keyof StepsType)[];
  currentStep: keyof StepsType;
}) {
  return (
    <Stack spacing={"0"} direction={{ base: "column", md: "row" }} mb={8}>
      {Object.keys(steps).map((stepKey, index) => (
        <Step
          key={stepKey}
          description={steps[stepKey].title}
          isActive={currentStep === stepKey}
          isCompleted={stepHistory.includes(stepKey)}
          isFirstStep={index === 0}
          isLastStep={Object.keys(steps).length === index + 1}
        />
      ))}
    </Stack>
  );
}
