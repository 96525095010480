import { Flex, Image } from "@chakra-ui/react";
import blobDots from "assets/blob-dots.png";
import blobSmall from "assets/blob-small.svg";
import blobLine from "assets/blob-line.svg";
import blobLarge from "assets/blob-large.svg";

export function PublicBlobs() {
  return (
    <Flex
      pos={"absolute"}
      w={"100vw"}
      h={"100vh"}
      left={0}
      right={0}
      bottom={0}
      top={0}
      overflow={"hidden"}
      align={"center"}
      justify={"center"}
      zIndex={-1}
      userSelect={"none"}
    >
      <Image
        src={blobDots}
        w={"320px"}
        pos={"absolute"}
        ml={"-830px"}
        zIndex={-2}
      />
      <Image
        src={blobSmall}
        w={"400px"}
        pos={"absolute"}
        ml={"-660px"}
        mt={"-200px"}
        zIndex={-1}
        color={"blue"}
      />

      <Image
        src={blobLine}
        w={"1000px"}
        h={"auto"}
        pos={"absolute"}
        zIndex={-1}
        bottom={0}
        right={0}
        mb={"-370px"}
        mr={"-250px"}
        maxW={"initial"}
      />

      <Image
        src={blobLarge}
        w={"520px"}
        pos={"absolute"}
        bottom={0}
        right={0}
        mb={"-50px"}
        zIndex={-1}
      />
    </Flex>
  );
}
