export const layerStyles = {
  "input-container": {
    borderColor: "gray.600",
    background: "solid",
    backgroundColor: "white",
    fontWeight: "medium",
    transitionDuration: "100ms",
    _hover: {
      borderColor: "brand.900",
    },
    _placeholder: {
      color: "gray.600",
    },
    _focusVisible: {
      borderColor: "brand.900",
      boxShadow: "0px 0px 0px 0.18rem var(--chakra-ui-focus-ring-color)",
    },
  },
};
