import {
  ChakraTheme,
  extendTheme,
  theme as baseTheme,
  withDefaultColorScheme,
} from "@chakra-ui/react";
import { textStyles } from "theme/text-styles";
import { layerStyles } from "theme/layer-styles";
import "theme/default-props";
import { colors } from "theme/colors";
import { components } from "theme/components";
import { fontSizes } from "theme/font-sizes";
import { styles } from "theme/styles";
import { shadows } from "theme/shadows";
import { fonts } from "theme/fonts";
import { fontWeights } from "theme/font-weights";

const lunaTheme = extendTheme(
  {
    ...baseTheme,
    textStyles,
    layerStyles,
    colors,
    components,
    fontSizes,
    fontWeights,
    shadows,
    styles,
    breakpoints: {
      nav: "1055px",
    },
    fonts,
  } as ChakraTheme,
  withDefaultColorScheme({ colorScheme: "brand" })
);

export { lunaTheme };
