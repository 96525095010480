import { defineStyleConfig } from "@chakra-ui/styled-system";
import { colors } from "theme/colors";

export const Heading = defineStyleConfig({
  baseStyle: {
    color: "brand.900",
    fontWeight: "extrabold",
    letterSpacing: "-0.02em",
  },
  sizes: {
    sm: {
      fontSize: "lg",
    },
    md: {
      fontSize: "2xl",
    },
    "2xl": {
      lineHeight: 1.2,
    },
    "3xl": {
      color: colors.slate["900"],
      fontWeight: "bold",
      lineHeight: 1.2,
    },
  },
});
