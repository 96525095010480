import {
  create as createModal,
  show as showModal,
  useModal,
} from "@ebay/nice-modal-react";
import {
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { Form } from "components/input/form";
import { FormError } from "patterns/form-error";
import { useState } from "react";
import { OneTimePasswordInput } from "components/input/one-time-password-input";
import { FormField } from "components/input/form-field";
import { AxiosError } from "axios";
import { Bugsnag } from "lib/bugsnag";

type FormData = {
  code: string;
};

type MultiFactorVerifyProps<OnSubmit = (data: FormData) => Promise<unknown>> = {
  message?: string;
  onSubmit: OnSubmit;
  onSubmitLabel?: string;
  isCloseable?: boolean;
};

export const MultiFactorVerify = createModal<MultiFactorVerifyProps>(
  (props) => {
    const defaultProps = {
      isCloseable: true,
      message:
        "To complete this action, please enter the 6 digit code " +
        "provided by your authenticator app",
      onSubmitLabel: "Submit",
      onSubmit: async () => null,
    };

    const { message, isCloseable, onSubmitLabel, onSubmit } = {
      ...defaultProps,
      ...props,
    };

    const modal = useModal();
    const [error, setError] = useState<string | undefined>();

    const form = useForm<FormData>({
      defaultValues: {
        code: "",
      },
    });

    const {
      formState: { isSubmitting },
      watch,
    } = form;

    const code = watch("code");

    return (
      <Modal
        isOpen={modal.visible}
        onClose={modal.hide}
        onCloseComplete={modal.remove}
        size={"lg"}
        isCentered
        closeOnEsc={isCloseable}
        closeOnOverlayClick={isCloseable}
      >
        <Form
          form={form}
          onSubmit={async (data) => {
            try {
              const payload = await onSubmit(data);
              modal.resolve(payload);
              await modal.hide();
              await modal.remove();
            } catch (e) {
              if (
                e instanceof AxiosError &&
                e?.response?.data?.exception === "ValidationException"
              ) {
                setError(e?.response?.data?.message);
              } else {
                setError("An unknown error occurred");
                Bugsnag.notify(e as Error);
                console.error(e);
              }
            }
          }}
        >
          <ModalOverlay bg={"blackAlpha.500"} />
          <ModalContent mt={0} mb={0}>
            <ModalHeader>Multi-factor verification</ModalHeader>
            {isCloseable ? <ModalCloseButton size={"lg"} /> : null}
            <ModalBody>
              <FormError error={error} />
              <Text mb={"4"}>{message}</Text>
              <FormField name={"code"} Input={OneTimePasswordInput} />
            </ModalBody>
            <ModalFooter>
              <Flex justify={"flex-end"}>
                <Button
                  type={"submit"}
                  isLoading={isSubmitting}
                  isDisabled={code.length < 6}
                >
                  {onSubmitLabel}
                </Button>
              </Flex>
            </ModalFooter>
          </ModalContent>
        </Form>
      </Modal>
    );
  }
);

export async function requestMultiFactorCode<
  OnSubmitResult = unknown,
  OnSubmit extends (data: FormData) => Promise<OnSubmitResult> = (
    data: FormData
  ) => Promise<OnSubmitResult>
>(props: MultiFactorVerifyProps<OnSubmit>) {
  return (await showModal(MultiFactorVerify, props)) as ReturnType<OnSubmit>;
}
