import { APPS } from "config/apps";
import { useSearch } from "wouter/use-location";
import { useMemo, useState } from "react";

export function useApp() {
  const search = Object.fromEntries(new URLSearchParams(useSearch()));
  const [appId, setAppId] = useState(search.app_id);

  const app = useMemo(
    () => APPS.find((app) => app.id === appId) as typeof APPS[number],
    [appId]
  );

  return { app, setAppId };
}
