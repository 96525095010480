import { Button, HStack, Input, Stack, VStack } from "@chakra-ui/react";
import { FormField } from "components/input/form-field";
import { useForm } from "react-hook-form";
import { Form } from "components/input/form";
import { LoginWizard } from "screens/login/hooks/use-login-wizard";

export function EmailForm({
  currentStep,
  state,
  state: { email },
  isLoading,
}: LoginWizard) {
  const form = useForm<Partial<typeof state>>({
    defaultValues: {
      email: email || "",
    },
  });

  return (
    <Form form={form} onSubmit={currentStep.onSubmit}>
      <VStack spacing={"6"} align={"stretch"}>
        <Stack spacing={"5"}>
          <FormField
            name={"email"}
            label={"Email address"}
            Input={Input}
            inputProps={{
              autoFocus: true,
              type: "email",
              autoCapitalize: "none",
              autoComplete: "email",
              autoCorrect: "off",
              "data-form-type": "email",
            }}
            required
          />
          <FormField
            name={"password"}
            Input={Input}
            inputProps={{
              type: "password",
              autoCapitalize: "none",
              autoCorrect: "off",
              autoComplete: "current-password",
              position: "absolute",
              opacity: 0,
              tabIndex: -1,
              pointerEvents: "none",
              "aria-hidden": "true",
            }}
          />
        </Stack>
        <HStack justify={"flex-end"}>
          <Button
            alignSelf={"flex-end"}
            variant={"solid"}
            type={"submit"}
            isLoading={isLoading}
            flexShrink={"0"}
            data-form-type={"action,next"}
          >
            Next
          </Button>
        </HStack>
      </VStack>
    </Form>
  );
}
