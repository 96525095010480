export class LoginError extends Error {
  constructor(message: string) {
    super(LoginError.CODES[message] || message);
  }
  static CODES = {
    ERR_OIDC_WRONG_EMAIL:
      "The email you used to log in via your third party provider does not match a user.",
    ERR_LOGIN_TOKEN_EXCHANGE:
      "An unexpected error occurred when logging you in, please try again",
    ERR_INVITE_INVALID:
      "The invite link is invalid, expired or has already been actioned",
  } as Record<string, string>;
}
